import chackbox from "../assets/img/chackbox.png"

const SliderComponent = (props) => {
    return (
        <div 
        className={props.highlight ? "medsavings-app__offerCard__body__carousel__component medsavings-app__offerCard__body__carousel__component--highlight" : "medsavings-app__offerCard__body__carousel__component"}>
            <div className="medsavings-app__offerCard__body__carousel__component__label">{props.label}</div>
            {props.isTick ? <div className="medsavings-app__offerCard__body__carousel__component__value"><img src={chackbox} alt="#"></img></div> : <div className="medsavings-app__offerCard__body__carousel__component__value">{props.value}</div>}            
        </div>
    )
}
export default SliderComponent