import React from "react";
import Inputs from "./Components/Inputs";
import OfferCardWrapper from "./Components/OfferCardWrapper";

interface AppProps {
  dataUrl: string
}
const App: React.FC<AppProps> = ({ dataUrl }) => {

  const [inputData, setInputData] = React.useState({
    monthlyPayment: 5000,
    oneTimePayment: 25000,
    monthlyPaymentLabel: 5000,
    oneTimePaymentLabel: 25000,
    monthlyPaymentMin: 1000,
    oneTimePaymentMin: 1000,
    paymentStep: 1000,
    dataSheet: null
  })  
console.log(inputData)
  React.useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal        
      fetch(dataUrl, {signal: signal})
      .then((response) => {
          return response.json()
      }).then((data) => {
          setInputData(() => {
              return {
                monthlyPayment: data.monthlyPayment,
                oneTimePayment: data.oneTimePayment,
                monthlyPaymentLabel: data.monthlyPayment,
                oneTimePaymentLabel: data.oneTimePayment,
                paymentStep: data.paymentStep,
                monthlyPaymentMin: data.monthlyPaymentMin,
                oneTimePaymentMin: data.oneTimePaymentMin,
                dataSheet: data
              }
          })
      })
      return () => {
          controller.abort()
      }
  }, [dataUrl])

  const handleInput = (event, minAmount) => {
    let labelName = event.target.name + 'Label'
    setInputData((prevInputData) =>{
      if (parseInt(event.target.value.replace(/\s/g, '')) >= 1) {         
        return {
          ...prevInputData,
          [labelName]: parseInt(event.target.value.replace(/\s/g, ''))
        }
      } else {
        return {
          ...prevInputData,
          [labelName]: minAmount
        }
      }
    })
  }

  const handleOnFocus = (event) => {
    let labelName = event.target.name + 'Label'
    setInputData((prevInputData) => {
      return {
        ...prevInputData,
        [labelName]: ' '
      }
    })
  }

  const handleBlur = (event, minAmount) => {
    let filteredValue = parseInt(event.target.value.replace(/\s/g, ''))
    let labelName = event.target.name + 'Label'
    setInputData((prevInputData) =>{
      if (filteredValue >= minAmount) {         
        return {
          ...prevInputData,
          [event.target.name]: filteredValue,
          [labelName]: filteredValue
        }
      } else if (filteredValue === 0) {
        return {
          ...prevInputData,
          [labelName]: prevInputData[event.target.name]
        }
      } else 
      {
        return {
          ...prevInputData,
          [event.target.name]: minAmount,
          [labelName]: minAmount
        }
      }
    })
  }
  
  const handleButtonClick = (paymentType, plusOrMinus, minAmount, labelType) => {
    let addValue
    let initValue
    if (plusOrMinus === 'plus') {
      addValue = inputData.paymentStep
    } else {
      addValue = inputData.paymentStep * -1
    }
    setInputData((prevInputData) => {
      if ((prevInputData[paymentType] / prevInputData.paymentStep) % 1 !== 0) {
        if (plusOrMinus === 'plus') {
          initValue = Math.floor(prevInputData[paymentType] / prevInputData.paymentStep) * prevInputData.paymentStep
        }
        if (plusOrMinus === 'minus') {
          initValue = Math.ceil(prevInputData[paymentType] / prevInputData.paymentStep) * prevInputData.paymentStep
        }
      } else {
        initValue = prevInputData[paymentType]
      }
      if (initValue + addValue >= minAmount) {
        return {
          ...prevInputData,
          [paymentType]: initValue + addValue,
          [labelType]: initValue + addValue
        }
      } else {
        return {
          ...prevInputData,
          [paymentType]: minAmount,
          [labelType]: minAmount
        }
      }
    })
  }

  return (
    <div className="medsavings-app container-xxl">
      <div className="medsavings-app__wrapper">
        <div className="medsavings-app__wrapper__inputs">
          <Inputs 
            label={inputData.dataSheet ? inputData.dataSheet.buttonLabelMonthly : 'Rendszeres vállalt havi befizetés:'}
            inputName='monthlyPayment'
            inputStep={inputData.paymentStep}
            inputValue={inputData.monthlyPaymentLabel}
            handleInput={(event, minAmount) => {handleInput(event, inputData.monthlyPaymentMin)}}
            handleClick={(paymentType, plusOrMinus, minAmount, labelType) => {handleButtonClick(paymentType, plusOrMinus, inputData.monthlyPaymentMin, labelType)}}
            minimumValue = {inputData.monthlyPaymentMin}
            handleOnFocus = {(event) => {handleOnFocus(event)}}
            handleBlur = {(event, minAmount) => {handleBlur(event, inputData.monthlyPaymentMin)}}
          />
          <Inputs 
            label={inputData.dataSheet ? inputData.dataSheet.buttonLabelOneTime : 'Egyszeri befizetések összege:'}
            inputName='oneTimePayment'
            inputStep={inputData.paymentStep}
            inputValue={inputData.oneTimePaymentLabel}
            handleInput={(event, minAmount) => {handleInput(event, inputData.oneTimePaymentMin)}}
            handleClick={(paymentType, plusOrMinus, minAmount, labelType) => {handleButtonClick(paymentType, plusOrMinus, inputData.oneTimePaymentMin, labelType)}}
            minimumValue = {inputData.oneTimePaymentMin}
            handleOnFocus = {(event) => {handleOnFocus(event)}}
            handleBlur = {(event, minAmount) => {handleBlur(event, inputData.oneTimePaymentMin)}}
          />
        </div>
        <OfferCardWrapper 
          dataSheet = {inputData.dataSheet}
          monthlyPayment = {inputData.monthlyPayment}
          oneTimePayment = {inputData.oneTimePayment}
        />
      </div>      
    </div>
  );
}

export default App;