import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('medicalsavings'));
const el = document.getElementById('medicalsavings')
const dataParam = el.getAttribute('data-param')
root.render(
  <React.StrictMode>
    <App dataUrl={dataParam} />
  </React.StrictMode>
);