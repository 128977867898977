const getOpexArray = (monthly, opexArray) => {
    let currentOpexArray = null
    for (let i = 0; i < opexArray.length; i++) {
        if (monthly <= (opexArray[i].opexTop)) {                     
            currentOpexArray = opexArray[i]
            break
        }
    }
    if (currentOpexArray === null) {
        currentOpexArray = opexArray[opexArray.length - 1]
    }
    return currentOpexArray
}

export default getOpexArray