const Inputs = (props) => {
    return (
        <div className="medsavings-app__wrapper__input">
        <div className="medsavings-app__wrapper__input__label">{props.label}</div>
        <div className="medsavings-app__wrapper__input__control">
            { props.inputStep >= props.inputValue ? 
                <div className="medsavings-app__wrapper__input__control__minus medsavings-app__wrapper__input__control__minus--disabled" onClick={() => (props.handleClick(props.inputName, 'minus', props.minimumValue, props.inputName + 'Label'))}>-</div> :
                <div className="medsavings-app__wrapper__input__control__minus" onClick={() => (props.handleClick(props.inputName, 'minus', props.minimumValue, props.inputName + 'Label'))}>-</div>
            }
          <input className="medsavings-app__wrapper__input__control__element" value={new Intl.NumberFormat("fr-FR").format(props.inputValue)} name={props.inputName} onFocus={(event) => {props.handleOnFocus(event)}} onChange={(event) => {props.handleInput(event)}} onBlur={(event) => {props.handleBlur(event)}} inputMode="numeric" ></input>
          <div className="medsavings-app__wrapper__input__control__plus" onClick={() => (props.handleClick(props.inputName, 'plus', props.minimumValue, props.inputName + 'Label'))}>+</div>
        </div>
      </div>
    )
}
export default Inputs