import React from "react"
import OfferCard from "./OfferCard"
import NoProductsAvailable from "./NoProductsAvailable"
import parse from 'html-react-parser'

const OfferCardWrapper = (props) => {
    let products = [null]

    if (props.dataSheet) {
        products = props.dataSheet.products.map((product) => {
            if (product.productMinMonthly <= props.monthlyPayment && product.productMinOnetime <= props.oneTimePayment) {
                return (
                    <OfferCard 
                        key = {product.productId}
                        taxKey = {props.dataSheet.taxKey}                        
                        monthlyPayment = {props.monthlyPayment}
                        oneTimePayment = {props.oneTimePayment}
                        productName = {product.productName}
                        productLogo = {product.productLogo}
                        productLogoCss = {product.productLogoCss}
                        productLink = {product.productLink}
                        productRating = {product.productRating}
                        productIsNew = {product.productIsNew}
                        productAttributes = {product.productAttributes}
                        productOpexRate = {product.productOpexRate}
                        productInfo = {product.productInfo}
                        productYearlyFee = {product.productYearlyFee}
                        showRating = {product.showRating}
                        taxMax = {props.dataSheet.taxMax}
                        entryFeeLabel = {props.dataSheet.entryFeeLabel}
                        cardFeeLabel = {props.dataSheet.cardFeeLabel}
                        ctaLabel = {props.dataSheet.ctaLabel}
                        totalDepositLabel = {props.dataSheet.totalDepositLabel}
                        spendAmountLabel = {props.dataSheet.spendAmountLabel}
                        firstYearSavingsLabel = {props.dataSheet.firstYearSavingsLabel}
                        opexLabel = {props.dataSheet.opexLabel}
                        reviewerLabel = {props.dataSheet.reviewerLabel}
                        showEntryFee = {props.dataSheet.showEntryFee}
                        showCardFee = {props.dataSheet.showCardFee}
                        showTotalSpendAmount = {props.dataSheet.showTotalSpendAmount}
                        allowTaxCredit = {props.dataSheet.allowTaxCredit}
                        showDots = {props.dataSheet.showDots}                        
                    />
                )
            } else {
                return null
            }
        })
    }
    return (
        <div className="medsavings-app__offerCard__wrapper">
            {props.dataSheet ? 
                (!products.some(el => el !== null) ?
                    <NoProductsAvailable 
                        label={props.dataSheet.noProductsLabel}
                    /> :
                    products
                ) :
                <NoProductsAvailable label="Az adatbázis jelenleg nem elérhető. Kérjük próbálja meg később!" />
            }
            {props.dataSheet && products.some(el => el !== null) && <div><i>{parse(props.dataSheet.disclaimerText)}</i></div>}            
        </div>
    )
}
export default OfferCardWrapper