const calculateYearlyFees = (yearlyFeeArray, entryFeeLabel, cardFeeLabel) => {
    let returnObject = {
        cardFee: {
            value: 0,
            label: null,
            highlight: false
        },
        entryFee: {
            value: 0,
            label: null,
            highlight: false
        },
        totalFees: 0
    }
    for (let elem of yearlyFeeArray) {
        if (elem.label === cardFeeLabel) {
            returnObject.cardFee.value = elem.fee
            returnObject.cardFee.label = elem.label
            returnObject.cardFee.highlight = elem.highlight
        }
        if (elem.label === entryFeeLabel) {
            returnObject.entryFee.value = elem.fee
            returnObject.entryFee.label = elem.label
            returnObject.entryFee.highlight = elem.highlight
        }
        if (elem.recurring) {
            returnObject.totalFees += (elem.fee * 12)
        } else {
            returnObject.totalFees += elem.fee
        }
    }
    return returnObject
}

export default calculateYearlyFees